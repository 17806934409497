import React from 'react';
import TextField from '@material-ui/core/TextField';

const CostAdjuster = (props) => {

    const handleChange = event => {
        props.onCostAdjustmentChange(event.target.value);
    };

    return (
        <div>
            <TextField
                id="costAdjustment"
                label="Cost Adjustment"
                type="number"
                value={props.costAdjustment}
                onChange={handleChange}
                variant="outlined"
                helperText="Adjust costs by a fixed percentage"
                InputProps={{ endAdornment: "%" }}
            />
        </div>
    );
};

export default CostAdjuster
