import React from 'react'
import TextField from '@material-ui/core/TextField';

const RecipePicker = (props) => {
    const handleChange = event => {
        let selectedRecipe = props.recipeList.find(value => value.id === event.target.value);

        props.onSelectionChange(selectedRecipe);
    };

    return (
        <div>
            <TextField
                id="recipePicker"
                select
                label="Recipe"
                value={props.activeRecipe.id}
                onChange={handleChange}
                SelectProps={{
                    native: true,
                }}
                helperText="Please choose a recipe"
                variant="outlined"
            >
                {
                    props.recipeList &&
                    props.recipeList.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                }
            </TextField>
        </div>
    )
}

export default RecipePicker