import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import fwblLogo from './fwbl-logo.png';
import BatchSizeInfo from './Components/BatchSizeInfo';
import BatchRevenueInfo from './Components/BatchRevenueInfo';
import FermenterCountSelector from './Components/FermenterCountSelector';
import ProductionInfo from './Components/ProductionInfo';
import RecipePicker from './Components/RecipePicker/RecipePicker';
import SystemSizeSelector from './Components/SystemSizeSelector';

import { DataBeer, HSIOW, OtherBeer } from './Data/recipes';
// import { PriceSheet } from './Data/prices';
import { AppBar, createTheme, Grid, Paper, ThemeProvider, Toolbar, Typography } from '@material-ui/core';
import CostAdjuster from './Components/CostAdjuster/CostAdjuster';


const theme = createTheme({
  palette: {
    primary: {
      main: "#0f476d"
    },
    secondary: {
      main: "#ffcb44"
    },
  }
});

const App = () => {
  const [systemSize, onSystemSizeChange] = useState("1.0");
  const [fermenterCount, onFermenterCountChange] = useState(4);
  const [activeRecipe, onRecipeSelectionChange] = useState(HSIOW);
  const [recipeList, setRecipeList] = useState([DataBeer, HSIOW, OtherBeer]);
  const [priceList, setPriceList] = useState([]);
  const [costAdjustment, onCostAdjustmentChange] = useState("0.0")

  useEffect(() => {
    const getRecipes = async () => {
      const response = await fetch(`/api/Recipes`);
      const data = await response.json();
      setRecipeList(data);
    };
    getRecipes();
  }, []);

  useEffect(() => {
    const getPrices = async () => {
      const response = await fetch(`/api/Prices`);
      const data = await response.json();
      setPriceList(data);
    };
    getPrices();
  }, []);

  return (
    <>
      {
        <ThemeProvider theme={theme}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item style={{ 'maxWidth': '20%' }}>
                  <img src={fwblLogo} alt="logo" style={{ width: '100%', 'maxHeight': '80px' }} />
                </Grid>
                <Grid item style={{ 'maxWidth': '80%' }}>
                  <Typography variant="h4"> Napkin Math </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Grid container justifyContent="space-evenly" style={{ 'paddingTop': '32px' }}>
            <Grid item container spacing={4} justifyContent="center" style={{ width: '100vw' }}>
              <Grid item>
                <SystemSizeSelector systemSize={systemSize} onSystemSizeChange={onSystemSizeChange} />
              </Grid>
              <Grid item>
                <FermenterCountSelector fermenterCount={fermenterCount} onFermenterCountChange={onFermenterCountChange} />
              </Grid>
              <Grid item>
                <RecipePicker activeRecipe={activeRecipe} recipeList={recipeList} onSelectionChange={onRecipeSelectionChange} />
              </Grid>
              <Grid item>
                <CostAdjuster costAdjustment={costAdjustment} onCostAdjustmentChange={onCostAdjustmentChange} />
              </Grid>
            </Grid>

            <Grid item container spacing={2} justifyContent="space-evenly">
              <Grid item style={{ 'minWidth': '350px' }}>
                <Paper elevation={5} style={{ padding: '.5rem' }}>
                  <BatchSizeInfo systemSize={systemSize} />
                </Paper>
              </Grid>
              <Grid item style={{ 'minWidth': '350px' }}>
                <Paper elevation={5} style={{ padding: '.5rem' }} >
                  <BatchRevenueInfo systemSize={systemSize} recipe={activeRecipe} priceSheet={priceList} costAdjustment={costAdjustment} />
                </Paper>
              </Grid>
              <Grid item style={{ 'minWidth': '350px' }}>
                <Paper elevation={5} style={{ padding: '.5rem' }}>
                  <ProductionInfo fermenterCount={fermenterCount} recipe={activeRecipe} />
                </Paper>
              </Grid>
            </Grid>

            <Grid item>
              <img src={logo} alt="logo" style={{ 'maxWidth': '100%' }} />
            </Grid>

          </Grid>
        </ThemeProvider>
      }
    </>
  );
}

export default App;
