import React from 'react';
import TextField from '@material-ui/core/TextField';

const SystemSizeSelector = (props) => {

    const handleChange = event => {
        props.onSystemSizeChange(event.target.value);
    };

    return (
        <div>
            <TextField
                id="systemSize"
                label="System Size"
                type="number"
                inputProps={{ min: 0, step: .5 }}
                value={props.systemSize}
                onChange={handleChange}
                variant="outlined"
                helperText="Please set the System Size"
            />
        </div>
    );
};

export default SystemSizeSelector;
