import React from 'react'
import TextField from '@material-ui/core/TextField';

const FermenterSizeSelector = (props) => {

    const handleChange = event => {
        props.onFermenterCountChange(event.target.value);
    };

    return (
        <div>
            <TextField
                id="fermenterCount"
                label="Fermenters"
                type="number"
                inputProps={{ min: 1 }}
                value={props.fermenterCount}
                onChange={handleChange}
                variant="outlined"
                helperText="Please set number of fermenters"
            />
        </div>
    )
}

export default FermenterSizeSelector