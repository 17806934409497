import React from 'react'
import { conversions } from '../../Data/constants';

const BatchRevenueInfo = (props) => {
    const revenuePerBatch = props.systemSize ? (props.recipe.pricePerServing * conversions.pintsPerBbl * props.systemSize) : 0;

    const materialCostPerBatch = () => {
        let cost = 0.0;

        if (props.systemSize && props.recipe.convertedCostPerBarrel)
            cost = (props.recipe.convertedCostPerBarrel * props.systemSize)

        if (props.systemSize && props.priceSheet && props.priceSheet.length > 0 && props.recipe.ingredients) {
            cost = calcRecipePrice(props.recipe, props.priceSheet);
        }

        if (props.costAdjustment)
            cost = adjust(cost, props.costAdjustment);

        return cost;
    }

    const calcRecipePrice = (recipe, priceSheet) => {
        let recipeSizeAdjustment = scaleRecipeTo1bbl(recipe.recipeSize, recipe.recipeSizeUnits);

        let map1 = recipe.ingredients.map(ingredient => ingredient.amount * priceSheet.find(price => price.name.toUpperCase() === ingredient.name.toUpperCase())?.price);
        return (map1.reduce((prev, current) => prev + current, 0) * props.systemSize * recipeSizeAdjustment);
    }

    const adjust = (cost, adjustment) => {
        return cost * (1 + adjustment / 100);
    }

    const scaleRecipeTo1bbl = (size, units) => {
        if (units === "gal")
            return 31 / size;

        return size;
    }

    return (
        <div>
            <p>Batch Revenue Information</p>
            <p className="materialCost">${materialCostPerBatch().toFixed(2)} cost for materials</p>
            <p>${props.recipe.pricePerServing.toFixed(2)} retail per pint</p>
            <p>${revenuePerBatch.toFixed(2)} total per batch</p>
            <p>${(revenuePerBatch - materialCostPerBatch()).toFixed(2)} net per batch</p>
        </div>
    )
}
export default BatchRevenueInfo
