export const DataBeer = {
    id: "data-beer",
    name: "Data Beer",
    convertedCostPerBarrel: 100,
    pricePerServing: 6.00,
    fermenterDays: 20
}

export const OtherBeer = {
    id: "other-beer",
    name: "Other Beer",
    convertedCostPerBarrel: 200,
    pricePerServing: 8.00,
    fermenterDays: 25
}

export const HSIOW = {
    id: "hsiow",
    name: "Holy Shit It's Only Wednesday",
    convertedCostPerBarrel: 500,
    pricePerServing: 8.00,
    fermenterDays: 10
}