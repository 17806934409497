import React from 'react'
import { conversions } from '../../Data/constants';

const BatchSizeInfo = (props) => {
    return (
        <div>
            <p>Batch Size Information</p>
            <p>{props.systemSize} bbl</p>
            <p>{(props.systemSize * conversions.gallonsPerBbl).toFixed(1)} gallons</p>
            <p>{props.systemSize * conversions.pintsPerBbl} pints</p>
        </div>
    )
}

export default BatchSizeInfo
