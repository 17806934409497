import React from 'react'

const ProductionInfo = (props) => {
    const daysPerMonth = 30;
    const production = Math.floor(daysPerMonth / props.recipe.fermenterDays * props.fermenterCount);

    return (
        <div>
            <p>Production Information &nbsp; </p>
            <i>{props.fermenterCount} fermenters</i>
            <p>{props.recipe.fermenterDays} days in fermenter</p>
            <p>{production} max batches per month</p>
        </div>
    )
}

export default ProductionInfo
